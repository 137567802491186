.custom-checkbox .form-check-input:checked {
  background-color: rgb(194, 152, 106);
  border-color: rgb(194, 152, 106);
}

.custom-checkbox .form-check-input {
  width: 28px;
  height: 28px;
}

.custom-checkbox .form-check-label:hover {
  color:  rgb(194, 152, 106);
  cursor: pointer;
}