.fs-18 {
    font-size: 18px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-14 p, .fs-14 h6, .fs-14 h5, .fs-14 h4, .fs-14 h3, .fs-14 h2, .fs-14 h1 {
    font-size: 14px !important;
}

.fs-12 {
    font-size: 12px !important;
}