#header {
  position: relative;
  z-index: 1000;
}

.header-expanded {
   /* Ocupa a tela toda */
    position: relative;
    z-index: 1000;
    display: flex;
    align-items: top;
    background: rgba(51, 51, 51, 0.9); /* Fundo mais opaco */
    transition: height 1.5s ease, background 0.5s ease;
}

.sign-section {
    opacity: 0.9;
    position: relative;
    z-index: 5; /* Menor que o header-expanded */
}

.row-container {
  justify-content: center;
}

/* Largura abaixo de 1600px */
@media (max-width: 1600px) {
  .row-container {
    justify-content: flex-start;
  }
}

