.react-calendar {
  min-width: 350px;
  max-width: 100%;
  background: white;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  box-sizing: border-box;
  text-decoration: none !important;
}
.customCalendar {
  min-width: 100%;
}
.react-calendar--doubleView {
  width: 100%;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 100%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  line-height: 11px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 50px;
  font-size: 14px;
  word-wrap: break-word;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  align-items: center;
  justify-content: center;

}
.react-calendar__navigation button {
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}
.react-calendar__navigation button[disabled] {
  background-color: yellow;
}
.react-calendar__navigation__label {
  word-wrap: break-word;
  line-height: none;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  text-decoration: none !important;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 300;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #D10000;
  font-weight: bolder;
  border: solid 3px white !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #e8e8e8;
  font-weight: bolder;
  border: solid 3px white !important;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2rem 0.5rem;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: red;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #c2976a71;
  // border: solid 3px white !important;
  
  color: #fff;
}
.react-calendar__tile--now {
  background: #fff;
  color: #00C95F;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #C2986A;
}
.react-calendar__tile--hasActive {
  background: #C2986A;
  border: solid 3px white !important;
  width: 100%; 
  color: #fff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #C2986A;
  border-radius: 0%;
  border: solid 3px white !important;
  width: 90px;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #C2986A;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #C2986A;
  border: solid 3px white !important;
  color: #fff;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
  color: #C2986A;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: initial;
  font-weight: bold;
  font-size: 0.75em;
  text-decoration: none;
  margin-bottom: 0.5rem;
  color: #000;
  border: solid 3px white !important;
}

.hasOneDemand {
  background-color: rgba(242, 185, 15, 0.4);
  color: #000;
  font-size: 12px;
  :hover {
    background-color: rgba(242, 185, 15, 0.6);
  }
}

.hasMoreThanOneDemand {
  background-color: rgb(234, 95, 89, 0.8);
  color: #000;
  font-size: 12px;
  :hover {
    background-color: rgb(234, 95, 89, 1);
  }
}

.hasHoliday {
  background-color: #009db92d;
  color: #000;
  font-size: 12px;
}