.Toastify-success {
  background-color: #00AF53;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  height: 6rem;
  width: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  padding: 0 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  margin-top: 1rem;
}

.Toastify-error {
  background-color: #B40000;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  height: 6rem;
  width: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  padding: 0 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  margin-top: 1rem;
}

