.upAnimate {
  width: 18px;
  height: 18px;
  animation: upAnimate 0.4s infinite alternate;
}

@keyframes upAnimate {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1.2);
  }
}