::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: #55555580;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}