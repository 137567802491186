.border-color {
  border: solid 1px #dededf !important;
}

.hover-pointer:hover {
  cursor: pointer;
}

.hover:hover {
  color: $primary !important;
}

.hover-danger:hover {
  color: $danger !important;
}

.cursor-pointer {
  cursor: pointer;
}

.border-style-dashed {
  border: 2px dashed #BFC9DB;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.swiper {
  width: 100%;
  padding-bottom: 25px;  
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 250px;
  height: 64px;
}

@media (max-width: 1200px) {
  .text-xs-nowrap {
      white-space: nowrap;
  }
}