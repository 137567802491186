.list-with-numbers {
    counter-reset: journey-demands-counter;

    li {
        display: flex;
        flex-flow: row nowrap;

        counter-increment: journey-demands-counter;
        margin-top: 10px;

        &:before {
            content: counter(journey-demands-counter);
            min-width: 32px;
            width: 32px;
            height: 32px;
            line-height: 32px;
    
            margin-right: 8px;
            background-color: #000;
            border-radius: 50%;
    
            color: #FFFFFF;
            text-align: center;
            font-weight: 700;
            font-size: 14px;
        }
    }

}
