.richArea {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  
    .DraftEditor-root {
      box-sizing: border-box;
      border: 1px solid #ddd;
      cursor: text;
      padding: 5px;
      border-radius: 2px;
      box-shadow: inset 0px 1px 6px -3px #ababab;
      background: #fefefe;
      overflow-y: auto;
      height: calc(100% - 36px);
      overflow-x: hidden;
    }
}